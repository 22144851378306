export default {
  delete_photo: false,
  organization_id: null,
  first_name: null,
  middle_name: null,
  last_name: null,
  gender: null,
  dob: null,
  occupation: null,
  educational_level: null,
  other_educational_level: null,
  date_joined: null,
  photo: null,
  address: null,
  contact: null,
  secondary_contact: null,
  email: null,
  country_id: null,
  region_id: null,
  district: null,
  community: null,
  bank_name: null,
  bank_account_no: null,
  branch_name: null,
  momo_no: null,
  marital_status: null,
  no_dependency: null,
  beneficiary_full_name: null,
  beneficiary_phone: null,
  status: 'Active',
}
