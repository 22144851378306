<template>
  <!--    Farmer Group Form-->
  <validation-observer
    ref="memberNotificationForm"
    v-slot="{invalid}"
  >
    <am-modal
      title="Send Notification"
      :show="notificationModalOpened"
      :show-overlay="sendingNotification"
      size="md"
      @onClose="closeMemberNotificationModal()"
    >
      <am-text-area
        v-model="notification.content"
        name="content"
        placeholder="Enter some content..."
        rules="required"
        label="Content"
        resize
      />
      <b-form-checkbox
        v-model="scheduled"
        class="mt-1 mb-1"
      >
        Schedule Notification
      </b-form-checkbox>
      <am-date-picker
        v-if="scheduled"
        v-model="notification.date"
        label="Date to Send"
        name="scheduled_date"
        rules="required"
        enable-time
      />

      <template slot="modal-actions">
        <b-row class="float-right">
          <am-button
            name="cancel"
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @click="closeMemberNotificationModal()"
          />
          <am-button
            name="send_notification"
            label="Send"
            :disabled="invalid"
            :loading="sendingNotification"
            loading-text="Loading..."
            @click="sendNotification()"
          />
        </b-row>
      </template>
    </am-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BFormCheckbox,
} from 'bootstrap-vue'
import { serialize } from 'object-to-formdata'
import { ValidationObserver } from 'vee-validate'
import AmDatePicker from '@/farmbusiness/components/AmDatePicker.vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmModal from '@/farmbusiness/components/AmModal.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import AmTextArea from '@/farmbusiness/components/AmTextArea.vue'

export default {
  name: 'MemberNotification',
  components: {
    AmTextArea,
    AmModal,
    AmButton,
    AmDatePicker,
    ValidationObserver,
    BRow,
    BFormCheckbox,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    selectedMembers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      notificationModalOpened: false,
      sendingNotification: false,
      scheduled: false,
      notification: {
        content: null,
        date: null,
      },
    }
  },
  watch: {
    modalOpened(opened) {
      this.notificationModalOpened = opened
      this.notification.content = null
      this.notification.date = null
      this.scheduled = false
    },
  },
  methods: {
    closeMemberNotificationModal() {
      this.notificationModalOpened = false
      this.$emit('modalClosed')
    },
    sendNotification() {
      if (!this.scheduled) {
        this.notification.date = null
      }
      this.sendingNotification = true
      const not = { ...this.notification, ids: this.selectedMembers.map(mem => mem.id) }
      this.$store.dispatch('member/sendNotification', not)
        .then(() => {
          this.showSuccess('Sent Successfully')
        })
        .catch(() => {
          this.showError('Failed to send notification')
        }).finally(() => {
          this.sendingNotification = false
        })
    },
  }
  ,
}
</script>
