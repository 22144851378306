<template>
  <div class="">
    <am-list-page
      title="Members"
      :columns="columns"
      :rows="members"
      :table-loading="tableLoading"
      :show-create="false"
      :show-search="true"
      :show-profile-photo="true"
      enable-multi-select
      show-details
      :remote="true"
      :total-rows="totalRows"
      :current-page="currentPage"
      :per-page="perPage"
      :show-refresh="false"
      search-placeholder="Type search term + Enter"
      @onSearch="handleSearch"
      @onPageChange="handlePageChange"
      @onPerPageChange="handlePerPageChange"
      @onSortChange="handleSortChange"
      @onRowSelect="handleRowSelect"
    >
      <template slot="right-extra-header-actions">
        <div
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -18px;"
        >
          <am-select
            v-model="memberStatus"
            variant="outline-primary"
            placeholder="Filter by Status"
            :options="statusTypes"
            class="mr-1"
            @input="fetchMembers()"
          />
          <am-button
            variant="outline-primary"
            icon="MessageCircleIcon"
            label="Notify"
            tool-tip-text="Send SMS notifications to members"
            class="mr-1"
            @click="showMemberNotificationModal()"
          />
          <am-button
            variant="outline-primary"
            icon="PrintIcon"
            label="Generate IDs"
            tool-tip-text="Generate ID Cards for Members"
            class="mr-1"
            @click="showIdCardGenerationOptionsModal()"
          />
          <am-button
            icon="SettingsIcon"
            label="Members"
            tool-tip-text="Manage Members"
            class=""
            @click="showMemberSetupModal()"
          />
        </div>
      </template>
      <template #row-details="{row}">
        <b-card>
          <b-row
            class="mb-2"
          >
            <div class="col-12 mb-1">
              <div class="d-flex align-items-center">
                <feather-icon
                  icon="UserIcon"
                  style="margin-right: 5px;"
                />
                <strong>More Personal Details</strong>
              </div>
            </div>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>Occupation : </strong>
              <span class="font-weight-bold">
                <b-badge variant="light-success">
                  {{ row.item.occupation || 'N/A' }}
                </b-badge>
              </span>
            </b-col>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>Educational Level : </strong>
              <span class="font-weight-bold">
                <b-badge variant="light-success">
                  {{ row.item.educational_level.toUpperCase() }}
                </b-badge>
              </span>
            </b-col>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>DoB : </strong>
              <span class="font-weight-bold">
                <b-badge variant="light-success">
                  {{ row.item.dob_label }}
                </b-badge>
              </span>
            </b-col>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>Age : </strong>
              <span class="font-weight-bold">
                <b-badge variant="light-success">
                  {{ row.item.age }}
                </b-badge>
              </span>
            </b-col>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>Date Joined : </strong>
              <span class="font-weight-bold">
                <b-badge variant="light-success">
                  {{ row.item.joined_date_label }}
                </b-badge>
              </span>
            </b-col>
            <div class="col-12 mt-1  mb-1">
              <div class="d-flex align-items-center">
                <feather-icon
                  icon="PhoneIcon"
                  style="margin-right: 5px;"
                />
                <strong>Contact Details</strong>
              </div>
            </div>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>Contact : </strong>
              <span class="font-weight-bold">
                <b-badge variant="light-success">
                  {{ row.item.contact }}
                </b-badge>
              </span>
            </b-col>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>Secondary Contact : </strong>
              <span class="font-weight-bold">
                <b-badge variant="light-success">
                  {{ row.item.secondary_contact || 'N/A' }}
                </b-badge>
              </span>
            </b-col>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>Email : </strong>
              <span class="font-weight-bold">
                <b-badge variant="light-success">
                  {{ row.item.email || 'N/A' }}
                </b-badge>
              </span>
            </b-col>
            <div class="col-12 mt-1  mb-1">
              <div class="d-flex align-items-center">
                <feather-icon
                  icon="MapPinIcon"
                  style="margin-right: 5px;"
                />
                <strong>Address Details</strong>
              </div>
            </div>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>Country : </strong>
              <span class="font-weight-bold">
                <b-badge variant="light-success">
                  {{ row.item.country }}
                </b-badge>
              </span>
            </b-col>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>Region : </strong>
              <span class="font-weight-bold">
                <b-badge variant="light-success">
                  {{ row.item.region }}
                </b-badge>
              </span>
            </b-col>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>District : </strong>
              <span class="font-weight-bold">
                <b-badge variant="light-success">
                  {{ row.item.district }}
                </b-badge>
              </span>
            </b-col>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>Community : </strong>
              <span class="font-weight-bold">
                <b-badge variant="light-success">
                  {{ row.item.community }}
                </b-badge>
              </span>
            </b-col>
            <div class="col-12 mt-1  mb-1">
              <div class="d-flex align-items-center">
                <feather-icon
                  icon="UsersIcon"
                  style="margin-right: 5px;"
                />
                <strong>Household & Next of Kin</strong>
              </div>
            </div>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>Marital Status : </strong>
              <span class="font-weight-bold">
                <b-badge variant="light-success">
                  {{ row.item.marital_status.toUpperCase() }}
                </b-badge>
              </span>
            </b-col>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>No Dependency : </strong>
              <span class="font-weight-bold">
                <b-badge variant="light-success">
                  {{ row.item.no_dependency }}
                </b-badge>
              </span>
            </b-col>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>Beneficiary Name : </strong>
              <span class="font-weight-bold">
                <b-badge variant="light-success">
                  {{ row.item.beneficiary_full_name }}
                </b-badge>
              </span>
            </b-col>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>Beneficiary Phone : </strong>
              <span class="font-weight-bold">
                <b-badge variant="light-success">
                  {{ row.item.beneficiary_phone }}
                </b-badge>
              </span>
            </b-col>
            <div class="full-width border-bottom border-gray" />
          </b-row>
        </b-card>
      </template>
      <template #details="{row}">
        <am-button
          icon-only
          variant="flat-primary"
          :icon="row.detailsShowing?'EyeOffIcon':'EyeIcon'"
          @click="row.toggleDetails"
        />
      </template>
      <template #gender="{row}">
        {{ row.item.gender.toUpperCase() }}
      </template>
      <template #created_at="{row}">
        {{ row.item.date_created }}
      </template>
      <template #status="{row}">
        <b-badge :variant="row.item.status==='Active'?'light-primary':'light-danger'">
          {{ row.item.status.toUpperCase() }}
        </b-badge>
      </template>
      <template #actions="{row}">
        <am-button
            icon-only
            icon="Edit2Icon"
            variant="flat-primary"
            tool-tip-text="Edit member details"
            @click="showMemberSetupModalForEdit(row.item)"
        />
      </template>
    </am-list-page>
    <member-notification
      :selected-members="selectedMembers"
      :modal-opened="memberNotificationModalOpened"
      @modalClosed="closeModal('notification')"
    />
    <member-setup
      :id="selectedMemberId"
      :modal-opened="membersModalOpened"
      @modalClosed="closeModal('members')"
    />
    <am-modal
      title="Generate ID Cards"
      ok-text="Generate"
      :show="idCardOptionModalOpened"
      :ok-disabled="generateForSelectedMembers?false:selectedOrganizations.length<1"
      :loading="generating"
      @onClose="idCardOptionModalOpened=false"
      @onOk="generateIdCards()"
    >
      <div class="row">
        <div
          v-if="selectedMembers.length"
          class="col-12 mb-1"
        >
          <b-form-checkbox v-model="generateForSelectedMembers">
            Generate & Download For Selected Members
          </b-form-checkbox>
        </div>
        <div
          v-if="!generateForSelectedMembers"
          class="col-12 mb-1"
        >
          ID cards will be generated and emailed to the contact persons of the selected organizations.
        </div>
        <div
          v-if="!generateForSelectedMembers"
          class="col-12 mb-1"
        >
          <am-multi-select
            v-model="selectedOrganizations"
            name="member_organizations"
            label-name="name"
            value-name="id"
            label="Select Organizations"
            placeholder="Member Organizations"
            rules="required"
            :options="organizations"
          />
        </div>
      </div>
    </am-modal>
  </div>
</template>
<script>
import {
  BBadge, BFormCheckbox, BCol, BRow,
} from 'bootstrap-vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmListPage from '@/farmbusiness/components/AmListPage.vue'
import AmDropdown from '@/farmbusiness/components/AmDropdown.vue'
import AmDropdownItem from '@/farmbusiness/components/AmDropdownItem.vue'
import MemberSetup from '@/views/membership/members/MemberSetup.vue'
import AmSelect from '@/farmbusiness/components/AmSelect.vue'
import AmModal from '@/farmbusiness/components/AmModal.vue'
import MemberNotification from '@/views/membership/members/MemberNotification.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import AmMultiSelect from '@/farmbusiness/components/AmMultiSelect.vue'
import downloader from '@/farmbusiness/mixins/downloader'

export default {
  name: 'Index',
  components: {
    AmMultiSelect,
    MemberNotification,
    AmModal,
    AmSelect,
    AmDropdownItem,
    AmDropdown,
    MemberSetup,
    AmListPage,
    AmButton,
    BBadge,
    BFormCheckbox,
    BCol,
    BRow,
  },
  mixins: [showToast, downloader],
  data() {
    return {
      generateForSelectedMembers: false,
      generating: false,
      tableLoading: false,
      selectedOrganizations: [],
      membersModalOpened: false,
      memberNotificationModalOpened: false,
      selectedMemberId: null,
      idCardOptionModalOpened: false,
      memberStatus: null,
      selectedMembers: [],
      statusTypes: ['Active', 'Inactive', 'Due Retirement'],
      currentPage: 1,
      perPage: 10,
      searchTerm: null,
      sort: {
        column: 'created_at',
        order: 'desc',
      },
      columns: [
        {
          key: 'multiselect',
          label: '',
        },
        {
          key: 'index',
          label: '#',
        },
        {
          label: 'Photo',
          key: 'photo',
        },
        {
          label: 'Id',
          key: 'member_id',
          sortable: true,
        },
        {
          label: 'Name',
          key: 'full_name',
          sortable: true,
        },
        {
          label: 'Gender',
          key: 'gender',
          sortable: true,
        },
        {
          label: 'Farmer Group',
          key: 'member_organization',
        },
        {
          label: 'Status',
          key: 'status',
          sortable: true,
        },
        {
          label: 'Date Created',
          key: 'created_at',
          sortable: true,
        },
        {
          label: 'Details',
          key: 'details',
        },
        {
          label: 'Actions',
          key: 'actions',
        },
      ],
    }
  },
  computed: {
    membershipSettings() {
      return this.$store.getters['member/membershipSettings']
    },
    members() {
      return this.$store.getters['member/members']
    },
    organizations() {
      return this.$store.getters['organization/organizations']
    },
    totalRows() {
      return this.$store.getters['member/totalRows']
    },
  },
  mounted() {
    this.searchTerm = this.membershipSettings.searchTerm
    this.memberStatus = this.membershipSettings.memberStatus
    this.currentPage = this.membershipSettings.currentPage
    this.perPage = this.membershipSettings.perPage
    // this.sort = this.membershipSettings.sort
  },
  methods: {
    setCurrentSettings() {
      this.$store.commit('member/setSettings', {
        memberStatus: this.memberStatus,
        currentPage: this.currentPage,
        perPage: this.perPage,
        searchTerm: this.searchTerm,
      })
    },
    handleRowSelect(items) {
      this.selectedMembers = items
    },
    showIdCardGenerationOptionsModal() {
      this.idCardOptionModalOpened = true
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'members':
          this.membersModalOpened = false
          this.selectedMemberId = null
          break
        case 'notification':
          this.memberNotificationModalOpened = false
          break
      }
    },
    showMemberSetupModalForEdit(item) {
      this.selectedMemberId = item.id
      this.membersModalOpened = true
    },
    showMemberSetupModal() {
      this.membersModalOpened = true
    },
    showMemberNotificationModal() {
      if (this.selectedMembers.length) {
        this.memberNotificationModalOpened = true
      } else {
        this.showInfo('Please select at least one member')
      }
    },
    fetchMembers() {
      const query = {
        search: this.searchTerm,
        column: this.sort.column,
        order: this.sort.order,
        per_page: this.perPage,
        page: this.currentPage,
        status: this.memberStatus,
      }
      this.setCurrentSettings()
      this.tableLoading = true
      this.$store.dispatch('member/fetchMembers', query).finally(() => {
        this.tableLoading = false
      })
    },
    generateIdCards() {
      let members = this.selectedMembers.map(m => m.id)
      let organizations = this.selectedOrganizations.map(m => m.id)
      if (this.generateForSelectedMembers) {
        organizations = []
      } else {
        members = []
      }
      this.generating = true
      this.$store.dispatch('member/generateIdCards',
        { for_members: this.generateForSelectedMembers, organizations, members }).then(res => {
        if (this.generateForSelectedMembers) {
          if (res) {
            this.downloadFile(res, 'Member ID Cards')
          }
        } else {
          this.showSuccess('Cards are being processed, organizations will receive their member cards very soon.')
        }
      }).catch(err => {
        this.showError('Failed to generate id cards')
      }).finally(() => {
        this.generating = false
      })
    },
    handleSearch(search) {
      this.searchTerm = search
      this.fetchMembers()
    },
    handlePageChange(page) {
      this.currentPage = page
      this.fetchMembers()
    },
    handlePerPageChange(perPage) {
      // if (!this.initializing) {
      this.perPage = perPage
      this.fetchMembers()
      // }
    },
    handleSortChange(sort) {
      this.sort.column = sort.sortBy === 'full_name' ? 'last_name' : sort.sortBy
      this.sort.order = sort.sortDesc ? 'desc' : 'asc'
      this.fetchMembers()
    },
    getLocationData(){
      if(this.$store.getters['organization/countries'].length===0){
        this.$store.dispatch("member/getLocationData")
      }
    }
  },
}
</script>

<style scoped>

</style>
