<template>
  <validation-provider
    #default="{ errors}"
    :sync-value="value"
    :vid="name"
    :name="formatName(name)"
    :rules="rules"
    :immediate="false"
  >
    <label
      v-if="label"
      :for="name"
    ><span v-html="`${label}`" /><b
      v-if="isRequired"
      class="text-danger"
      style="font-size: 15px"
    >*</b></label>
    <multiselect
      :id="name"
      :value="selectedValue"
      :multiple="true"
      :label="labelName"
      :track-by="valueName"
      :options="options"
      placeholder="Search + Enter"
      :close-on-select="false"
      :name="name"
      :class="`am_multi_select ${isDarkMode?'am-bg-dark':''}`"
      @input="handleSelectChange"
      @remove="handleRemove"
    />
    <small
      v-if="touched"
      class="text-danger text-capitalize"
    >{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import Multiselect from 'vue-multiselect'

export default {
  name: 'AmMultiSelect',
  components: {
    ValidationProvider,
    Multiselect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: 'select',
    },
    labelName: {
      type: String,
      default: 'label',
    },
    valueName: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select One',
    },
    rules: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      touched: false,
      selectedValue: [],
    }
  },
  computed: {
    isRequired() {
      return this.rules.includes('required')
    },
    isDarkMode() {
      const skin = this.$store.getters['appConfig/skin']
      return skin === 'dark'
    },
  },
  watch: {
    value(val) {
      this.selectedValue = val
      this.touched = true
    },
  },
  mounted() {
    this.selectedValue = this.value
  },

  methods: {
    formatName(name) {
      return name.replaceAll('_', ' ')
    },
    handleSelectChange(e) {
      this.$emit('input', e)
    },
    handleRemove(value, id) {
      this.$emit('remove', value.id)
    },
    // handleSelect(e){
    //   console.log(e,'select')
    // }
  },
}
</script>

<style lang="css" scoped>
.am-bg-dark >>> .multiselect__tags {
  background-color: #283046 !important;
}
.am-bg-dark >>> .multiselect__input{
  background-color: #283046 !important;
  caret-color: #fff;
}
.am-bg-dark >>> .multiselect__content-wrapper{
  background-color: #283046 !important;
  color: white;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
