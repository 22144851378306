<template>
  <div>
    <!--    Member Form-->
    <validation-observer
      ref="memberForm"
      v-slot="{invalid}"
    >
      <am-modal
        :title="`${updateMemberMode?'Update':'Register'} Member`"
        :show="memberModalOpened"
        :show-overlay="deletingMember"
        size="lg"
        @onClose="closeMemberModal()"
      >
        <b-row class="align-items-start">
          <div class="col-7">
            <am-select
              v-model="selectedMemberId"
              placeholder="All Members"
              :options="members"
              label-name="full_name"
              value-name="id"
              name="members"
              @input="populateMemberForm"
            />
          </div>
          <div class="col-2">
            <am-dropdown
              icon="TrashIcon"
              name="delete_member"
            >
              <b-dropdown-item
                id="cd_member"
                @click="deleteMember()"
              >
                Yes
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item>
                No
              </b-dropdown-item>
            </am-dropdown>
          </div>
          <div class="col-2">
            <am-button
              name="member_action"
              variant="flat-danger"
              icon="XIcon"
              label=""
              tool-tip-text="Clear Form"
              @click="resetMemberForm()"
            />
          </div>
        </b-row>
        <error-display :error="error" />
        <app-collapse
          accordion
          type="margin"
        >
          <app-collapse-item
            id="personal-details"
            title="Personal Details"
          >
            <div class="row">
              <div class="col-md-6">
              <am-input
                  v-model="member.first_name"
                  name="first_name"
                  label="First Name"
                  rules="required"
              />
              </div>
              <div class="col-md-6">
              <am-input
                  v-model="member.middle_name"
                  name="middle_name"
                  label="Middle Name (Optional)"
              />
              </div>
              <div class="col-md-6">
              <am-input
                  v-model="member.last_name"
                  name="last_name"
                  label="Last Name"
                  rules="required"
              />
              </div>
              <div class="col-md-6">
              <am-select
                  v-model="member.gender"
                  value-name="id"
                  label-name="name"
                  name="gender"
                  label="Gender"
                  rules="required"
                  :options="genders"
              />
              </div>
              <div class="col-md-6">
              <am-date-picker
                  id="dob"
                  v-model="member.dob"
                  name="date_of_birth"
                  label="Date of Birth"
                  rules="required"
              />
              </div>
              <div class="col-md-6">
              <am-input
                  v-model="member.occupation"
                  name="other_occupation"
                  label="Other Occupation (Optional)"
              />
              </div>
              <div class="col-md-6">
              <am-select
                  v-model="member.educational_level"
                  name="educational_level"
                  label="Educational Level"
                  label-name="name"
                  value-name="id"
                  rules="required"
                  :options="educationalLevels"
              />
              </div>
              <div class="col-md-6" v-if="hasOtherEducationalLevel">
              <am-input
                  v-model="member.other_educational_level"
                  name="educational_level"
                  label="Other Educational Level"
                  rules="required"
              />
              </div>
              <div class="col-md-6">
              <am-select
                  v-model="member.organization_id"
                  value-name="id"
                  label-name="name"
                  name="farmer_group"
                  label="Farmer Group"
                  rules="required"
                  :options="organizations"
              />
              </div>
              <div class="col-md-6">
              <am-date-picker
                  id="d_joined"
                  v-model="member.date_joined"
                  name="date_joined"
                  label="Date Joined"
                  rules="required"
              />
              </div>
              <div class="col-md-6">
              <am-select
                  v-model="member.status"
                  value-name="id"
                  label-name="name"
                  name="status"
                  label="Status"
                  rules="required"
                  :options="memberStatuses"
              />
              </div>
            </div>
          </app-collapse-item>
          <app-collapse-item
            id="contact-details"
            title="Contact Details"
          >
            <div class="row">
              <div class="col-md-6">
              <am-input
                  v-model="member.address"
                  name="address"
                  label="Address"
                  rules="required"
              />
              </div>
              <div class="col-md-6">
              <am-input
                  v-model="member.contact"
                  name="contact"
                  label="Contact"
                  rules="required|min_length:10|max_length:15"
              />
              </div>
              <div class="col-md-6">
              <am-input
                  v-model="member.secondary_contact"
                  name="secondary_contact"
                  label="Secondary Contact (Optional)"
                  rules="min_length:10|max_length:15"
              />
              </div>
              <div class="col-md-6">
              <am-input
                  v-model="member.email"
                  name="email"
                  label="Email (Optional)"
                  rules="email"
              />
              </div>
              <div class="col-md-6">
              <am-select
                  v-model="member.country_id"
                  name="country"
                  label="Country"
                  rules="required"
                  value-name="id"
                  label-name="name"
                  :options="countries"
              />
              </div>
              <div class="col-md-6">
              <am-select
                  v-model="member.region_id"
                  name="region"
                  label="Region"
                  rules="required"
                  value-name="id"
                  label-name="name"
                  :options="filteredRegions"
              />
              </div>
              <div class="col-md-6">
              <am-input
                  v-model="member.district"
                  name="district"
                  label="District"
                  rules="required"
              />
              </div>
              <div class="col-md-6">
              <am-input
                  v-model="member.community"
                  name="community"
                  label="Community"
                  rules="required"
              />
              </div>
            </div>
          </app-collapse-item>
          <app-collapse-item
            id="financial-details"
            title="Financial Details (Optional)"
          >
            <div class="row">
              <div class="col-md-6">
              <am-input
                  v-model="member.bank_name"
                  name="bank_name"
                  label="Bank Name"
              />
              </div>
              <div class="col-md-6">
              <am-input
                  v-model="member.bank_account_no"
                  name="bank_account_no"
                  label="Bank Account No"
                  rules="|min:12|max:20"
              />
              </div>
              <div class="col-md-6">
              <am-input
                  v-model="member.branch_name"
                  name="branch_name"
                  label="Branch Name"
              />
              </div>
              <div class="col-md-6">
              <am-input
                  v-model="member.momo_no"
                  name="mobile_money_no"
                  label="Momo Number"
                  rules="min:10|max:15"
              />
              </div>
            </div>
          </app-collapse-item>
          <app-collapse-item
            id="next-of-kin-details"
            title="Household & Next of Kin"
          >
            <div class="row">
              <div class="col-md-6">
              <am-select
                  v-model="member.marital_status"
                  name="marital_status"
                  label="Marital Status"
                  rules="required"
                  label-name="name"
                  value-name="id"
                  :options="maritalStatuses"
              />
              </div>
              <div class="col-md-6">
              <am-input
                  v-model="member.no_dependency"
                  name="number_of_dependency"
                  label="No Of Dependency"
                  rules="required|numeric"
              />
              </div>
              <div class="col-md-6">
              <am-input
                  v-model="member.beneficiary_full_name"
                  name="beneficiary_full_name"
                  label="Beneficiary Full Name"
                  rules="required"
              />
              </div>
              <div class="col-md-6">
              <am-input
                  v-model="member.beneficiary_phone"
                  name="beneficiary_phone"
                  label="Beneficiary Phone"
                  rules="required|min_length:10|max_length:15"
              />
              </div>
            </div>
          </app-collapse-item>
        </app-collapse>
        <div class="row mt-2">
          <div class="col-12 mb-1">
            <am-file-button
              label="Upload Photo"
              variant="outline-primary"
              @onFileUpload="handleFileUpload"
            />
          </div>
          <div
            v-if="memberPhoto"
            class="col-12 mt-1 mb-2"
          >
            <label>Member Photo</label>
            <div class="d-flex align-items-center">
              <am-avatar-data-url
                :size="60"
                :src="memberPhoto"
              />
              <div class="mr-1" />
              <am-button
                :icon-only="true"
                icon="XIcon"
                variant="flat-danger"
                @click="removePhoto()"
              />
            </div>
          </div>
          <div class="col-12">
            <b-form-checkbox
              v-model="showCapture"
              class="mb-1"
            >
              Capture Photo
            </b-form-checkbox>
          </div>
          <div v-if="showCapture">
            <b-row class="justify-content-center">
              <web-cam
                ref="webCam"
                style="border:5px greenyellow solid;border-radius:5px;background-color: green;"
                :height="350"
                :width="350"
                :select-first-device="true"
                :resolution="{ height: 500, width: 500 }"
                @started="onCameraStart"
                @cameras="setCameras"
              />
              <div
                class="mt-1 flex justify-content-center"
                style="width: 350px;"
              >
                <am-select
                  :options="cameras"
                  label-name="label"
                  value-name="deviceId"
                  placeholder="Select Camera"
                  @input="changeCamera"
                />
              </div>
            </b-row>
            <div class="row justify-content-center ">
              <am-button
                :disabled="!streamHasStarted"
                variant="flat-success"
                label="Capture"
                icon="CameraIcon"
                @click="onClick()"
              />
              <div class="mr-1" />
              <am-button
                :disabled="!streamHasStarted"
                label="Stop"
                variant="flat-danger"
                icon="StopCircleIcon"
                @click="stopCamera()"
              />
            </div>
          </div>
        </div>
        <template slot="modal-actions">
          <b-row class="float-right">
            <am-button
              name="cancel"
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @click="closeMemberModal()"
            />
            <am-button
              name="save_member"
              :label="updateMemberMode?'Update':'Register'"
              :disabled="invalid || photoInvalid"
              :loading="memberLoading"
              loading-text="Loading..."
              @click="updateMemberMode?updateMember():createMember()"
            />
          </b-row>
        </template>
      </am-modal>
    </validation-observer>
    <am-modal
      title="Crop Image"
      :show="showCropper"
      size="md"
      @onClose="onCropperClosed()"
    >
      <cropper
        class="cropper"
        :src="memberPhoto"
        :stencil-props="{
          aspectRatio: 10 / 12
        }"
        @change="getImage"
      />
      <template slot="modal-actions">
        <b-row student="float-right">
          <am-button
            variant="flat-danger"
            icon="XIcon"
            student="mr-1"
            label="Cancel"
            @click="onCropperClosed()"
          />
          <div style="margin-right: 10px;" />
          <am-button
            label="Save Image"
            @click="saveCroppedImage()"
          />
        </b-row>
      </template>
    </am-modal>
  </div>
</template>

<script>
import {
  BRow, BDropdownItem, BDropdownDivider, BFormCheckbox,
} from 'bootstrap-vue'
import { serialize } from 'object-to-formdata'
import { ValidationObserver } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import logData from '@/libs/log'
import AmInput from '@/farmbusiness/components/AmInput.vue'
import AmDatePicker from '@/farmbusiness/components/AmDatePicker.vue'
import AmSelect from '@/farmbusiness/components/AmSelect.vue'
import AmFileButton from '@/farmbusiness/components/AmFileButton.vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmDropdown from '@/farmbusiness/components/AmDropdown.vue'
import AmModal from '@/farmbusiness/components/AmModal.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import ErrorDisplay from '@/farmbusiness/components/AmErrorDisplay.vue'
import { WebCam } from 'vue-web-cam'
import { Cropper } from 'vue-advanced-cropper'
import urlToFile from '@/farmbusiness/mixins/urlToFile'
import mem from './defaultMember'
import 'vue-advanced-cropper/dist/style.css'
import AmAvatarDataUrl from '@/farmbusiness/components/AmAvatarDataUrl.vue'

export default {
  name: 'MemberSetup',
  components: {
    AmAvatarDataUrl,
    ErrorDisplay,
    AmModal,
    AmDropdown,
    AmButton,
    AmFileButton,
    AmSelect,
    AmDatePicker,
    AmInput,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    AppCollapse,
    AppCollapseItem,
    Cropper,
    WebCam,
    BFormCheckbox,
  },
  mixins: [showToast, urlToFile],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      memberModalOpened: false,
      updateMemberMode: false,
      memberLoading: false,
      deletingMember: false,
      selectedMemberId: null,
      member: { ...mem },
      memberPhoto: null,
      triggerRegionsReload: false,

      streamHasStarted: false,
      showCapture: false,
      showCropper: false,

      cameras: [],

      memberStatuses: [
        { id: 'Active', name: 'Active' },
        { id: 'Inactive', name: 'Inactive' },
      ],
      educationalLevels: [
        { id: 'primary', name: 'Primary' },
        { id: 'secondary', name: 'Secondary' },
        { id: 'tertiary', name: 'Tertiary' },
        { id: 'other', name: 'Other' },
      ],
      genders: [
        { id: 'male', name: 'Male' },
        { id: 'female', name: 'Female' },
      ],
      maritalStatuses: [
        { id: 'single', name: 'Single' },
        { id: 'married', name: 'Married' },
        { id: 'divorced', name: 'Divorced' },
      ],
    }
  },
  computed: {
    countryId(){
      return this.member.region_id = null
    },
    organizations() {
      return this.$store.getters['organization/organizations']
    },
    hasOtherEducationalLevel() {
      return this.member.educational_level === 'other'
    },
    photoInvalid() {
      if (!this.showCapture) {
        return false
      }
      return !this.member.photo
    },
    selectedMember() {
      const member = this.members.find(o => o.id === this.selectedMemberId)
      if (!member) {
        return null
      }
      return {
        ...member,
      }
    },
    members() {
      return this.$store.getters['member/members']
    },
    countries() {
      return this.$store.getters['organization/countries']
    },
    regions() {
      return this.$store.getters['organization/regions']
    },
    filteredRegions() {
      return this.regions.filter(re => re.country_id === +this.member.country_id)
    },
  },
  watch: {
    countryId(val){
      this.member.region_id = null
    },
    modalOpened(opened) {
      this.memberModalOpened = opened
    },
    id(id) {
      this.selectedMemberId = id
    },
  },
  methods: {
    onCameraStart(e) {
      this.streamHasStarted = true
    },
    changeCamera(deviceId) {
      if (deviceId) {
        this.$refs.webCam.changeCamera(deviceId)
      }
    },
    handleFileUpload(upload) {
      this.memberPhoto = upload.dataUrl
      this.member.logo = upload.file
      this.showCropper = true
    },
    removePhoto() {
      this.member.photo = null
      this.member.delete_photo = true
      this.memberPhoto = null
    },
    stopCamera() {
      this.$refs.webCam.stop()
      this.showCapture = false
    },
    onCropperClosed() {
      this.showCropper = false
    },
    saveCroppedImage() {
      // convert it to a usable data URL
      this.memberPhoto = this.canvas.toDataURL()
      this.member.photo = this.urlToFile(this.memberPhoto, 'png')
      // eslint-disable-next-line no-unused-expressions
      this.$refs.webCam?.stop()
      this.showCapture = false
      this.showCropper = false
    },
    getImage(e) {
      this.canvas = e.canvas
    },
    onClick() {
      this.memberPhoto = this.$refs.webCam.capture()
      this.showCropper = true
    },
    setCameras(cameras) {
      this.cameras = cameras
    },
    resetMemberForm() {
      this.member = { ...mem }
      this.selectedMemberId = null
      this.updateMemberMode = false
      this.memberPhoto = null
      this.$refs.memberForm.reset()
    },
    populateMemberForm(member) {
      if (this.selectedMember) {
        this.updateMemberMode = true
        this.member = { ...this.selectedMember, photo: null }
        this.memberPhoto = this.selectedMember.photo
        setTimeout(()=>{
          this.member.region_id = this.selectedMember.region_id
        },700)
      } else {
        this.resetMemberForm()
      }
    },
    closeMemberModal() {
      this.memberModalOpened = false
      this.$emit('modalClosed')
    },
    createMember() {
      this.error = {}
      this.memberLoading = true
      const member = serialize(this.member)
      this.$store.dispatch('member/createMember', member)
        .then(res => {
          this.showSuccess('Created member successfully')
          this.resetMemberForm()
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to create member')
        }).finally(() => {
          this.memberLoading = false
        })
    },
    updateMember() {
      this.error = {}
      if (!this.member.id) {
        return
      }
      this.memberLoading = true
      if (!this.member.logo) {
        delete this.member.logo
      }
      const member = serialize(this.member)
      this.$store.dispatch('member/updateMember', { member, id: this.member.id })
        .then(res => {
          this.showSuccess('Updated member successfully')
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to update member')
        }).finally(() => {
          this.memberLoading = false
        })
    },
    deleteMember() {
      if (!this.selectedMemberId) {
        this.showInfo('Please select a member')
        return
      }
      this.deletingMember = true
      this.$store.dispatch('member/deleteMember', this.selectedMemberId)
        .then(() => {
          this.showSuccess('Deleted Successfully')
          this.resetMemberForm()
        })
        .catch(() => {
          this.showError('Failed to delete member')
        }).finally(() => {
          this.deletingMember = false
        })
    },
  }
  ,
}
</script>
